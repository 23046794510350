import Vue from 'vue';
import * as MUTATION from '../mutationTypes';

export default {
  [MUTATION.UPDATE_CHANNEL_KEY](state, data) {
    state.channel_key = data.key;
    Vue.$storage.set('content_type', data.content_type);
    Vue.$storage.set('channel_key', data.key);
  },
  
  [MUTATION.UPDATE_API_KEY](state, data) {
    state.api_key = data.data.api_key;
    Vue.$storage.set('api_key', data.data.api_key);
  },
  
  [MUTATION.UPDATE_USER_INFO](state, data) {
    if (data && data.data) {
      state.user = data.data;
    }
  },
  
  [MUTATION.CLEAR_USER_INFO](state) {
    state.user = null;
    Vue.$storage.remove('api_key');
  },
};
