import Vue from 'vue';
import {LANGUAGES} from '@/const';
import dayjs from 'dayjs';
import * as MUTATIONS from '../mutationTypes';
import {createColorVariables} from '@/utils/colorScheme';
import GoogleAnalytics from '@/components/base-components/Analytics/GoogleAnalytics';

export default {
  [MUTATIONS.UPDATE_CHANNEL_SETTINGS](state, data) {
    state.settings = data.data;
    if (LANGUAGES[data.data.language]) {
      Vue.i18n.add(data.data.language, LANGUAGES[data.data.language]);
      Vue.i18n.set(data.data.language);
      import(`dayjs/locale/${data.data.language}.js`).then(() => {
        dayjs.locale(data.data.language);
      });
    } else {
      Vue.i18n.add('en', LANGUAGES['en']);
      Vue.i18n.set('en');
    }
    data?.data?.['color-scheme'] && createColorVariables(data.data['color-scheme']);
    if (data?.data?.['google-analytics']?.['google-tag']) {
      GoogleAnalytics.trackingId = data?.data?.['google-analytics']?.['google-tag'];
    }
  },
  
  [MUTATIONS.UPDATE_CHANNEL_MENU](state, data) {
    state.menu = data.data;
  },
  
  [MUTATIONS.UPDATE_CHANNEL_SLIDES](state, data) {
    state.slides = data.data;
  },
  [MUTATIONS.UPDATE_CHANNEL_PAGES](state, data) {
    state.pages = data.data.data;
  },
};
