import {invert} from 'lodash';
import en from './locales/en.json';
import fr from './locales/fr.json';

export const VUE_APP_URL = process.env.VUE_APP_URL;
export const VUE_APP_MEDIA_URL = process.env.VUE_APP_MEDIA_URL;
export const NODE_ENV = process.env.NODE_ENV;
export const VUE_APP_CHANNEL_NAME = process.env.VUE_APP_CHANNEL_CONFIG_NAME;

const schema = 'https:';
export const API = {
  API_HOST: `${schema}//${VUE_APP_URL}/api`,
  API_KEYS_HOST: `${schema}//${VUE_APP_URL}/storage/settings/`,
  MEDIA_HOST: `${schema}//${VUE_APP_MEDIA_URL}`,
};

export const LANGUAGES = {
  en,
  fr,
};

export const CHANNEL_TYPE = {
  conference: 'sport_conference',
  league: 'sport_league',
  entertainment: 'entertainment',
};

export const MEDIA_TYPE = {
  image: 1,
  live: 2,
  vod: 3,
  embedded: 4,
  thumbnails: 5,
  preview: 6,
  preview_alt: 7,
  embedded_preview: 8,
};
export const ASSETS_TYPE = {
  preview: 'preview',
  image: 'image',
  video: 'video',
  embed: 'embed',
};

export const URL_QUERIES = {
  CATEGORY: 'category_id',
};

export const ALL_CATEGORY = {
  title: 'All',
  id: 0,
  parent_id: 0,
};

export const MEDIA_TYPE_NAME_BY_ID = invert(MEDIA_TYPE);

export const MENU_POSITION = {
  header: 'header',
  footer: 'footer',
  both: 'both',
};

export const STREAM_STATUS = {
  vod: 'finished',
  live: 'live',
  upcoming: 'upcoming',
};

export const EVENT_TYPE = {
  free: 'free',
  paid: 'paid',
  register: 'register',
  ppv: 'ppv',
};

export const ORDER_STATUS = {
  paid: 'paid',
  expired: 'expired',
};

export const AUTH_PROVIDERS = {
  FACEBOOK: 'facebook',
  GOOGLE: 'google',
};

export const AUTH_TYPE = {
  LOGIN: 'login',
  REGISTRATION: 'registration',
};

export const VALIDATION_REG_EXP = {
  // eslint-disable-next-line max-len
  email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  numberEmail: /^[\d()]+$/,
  rePhone: /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/,
};
export const RECAPTCHA_SITE_KEY = '6LeXIfoiAAAAAKrR22sMJ5uu9JOvBqxLi6hpcFy7';

export const ERROR = {
  // eslint-disable-next-line max-len
  EMAIL_ALREADY_EXIST: 'The email address you have entered is already registered.',
  WRONG_AUTH_DATA: 'Wrong auth data',
  ALREADY_LOGGED: 'You are already logged in',
  VERIFY_FAILED: 'Verification failed.',
  EMAIL_ALREADY_VERIFY: 'Email already verified.',
  USER_NOT_FOUND: 'User not found.',
  RESET_PSWD_KEY_EXPIRED: 'Reset password key expired.',
  RESET_PSWD_FAILED: 'Reset password failed.',
  CANCELED: 'ERR_CANCELED',
  WRONG_PROMOCODE: 'Wrong promo code',
  GOOGLE_TOKEN: 'Google recaptcha token required',
  ROBOT: 'Google recaptcha token is invalid or you look like a robot',
  WRONG_EMAIL: 'A valid email address is required',
  SHORT_PASSWORD: 'The password is too short',
  PASS_DONT_MATCH: 'PASSWORDS DON’T MATCH',
  EMAIL_EXIST: 'The email address you have entered is already registered',
  ENTER_EMAIL: 'Enter your Email',
  ENTER_FIRST_NAME: 'Enter your first name',
  ENTER_LAST_NAME: 'Enter your last name',
};

export const TIME_ZONES = [
  {
    name: 'EDT',
    value: 'America/Toronto',
  },
  {
    name: 'Bogota',
    value: 'America/Bogota',
  },
  {
    name: 'Buenos Aires',
    value: 'America/Argentina/Buenos_Aires',
  },
  {
    name: 'Sao Paulo',
    value: 'America/Sao_Paulo',
  },
  {
    name: 'Madrid',
    value: 'Europe/Madrid',
  },
  {
    name: 'Paris',
    value: 'Europe/Paris',
  },
  {
    name: 'Rome',
    value: 'Europe/Rome',
  },
  {
    name: 'Prague',
    value: 'Europe/Prague',
  },
  {
    name: 'Amsterdam',
    value: 'Europe/Amsterdam',
  },
  {
    name: 'Berlin',
    value: 'Europe/Berlin',
  },
  {
    name: 'Helsinki',
    value: 'Europe/Helsinki',
  },
  {
    name: 'Zurich',
    value: 'Europe/Zurich',
  },
  {
    name: 'Stockholm',
    value: 'Europe/Stockholm',
  },
  {
    name: 'Moscow',
    value: 'Europe/Moscow',
  },
  {
    name: 'Johannesburg',
    value: 'Africa/Johannesburg',
  },
  {
    name: 'Cairo',
    value: 'Africa/Cairo',
  },
  {
    name: 'Dubai',
    value: 'Asia/Dubai',
  },
  {
    name: 'Colombo',
    value: 'Asia/Colombo',
  },
  {
    name: 'Istanbul',
    value: 'Asia/Istanbul',
  },
  {
    name: 'Bangkok',
    value: 'Asia/Bangkok',
  },
  {
    name: 'Taipei',
    value: 'Asia/Taipei',
  },
  {
    name: 'Singapore',
    value: 'Asia/Singapore',
  },
  {
    name: 'Seoul',
    value: 'Asia/Seoul',
  },
  {
    name: 'Hong Kong',
    value: 'Asia/Hong_Kong',
  },
  {
    name: 'Sydney',
    value: 'Australia/Sydney',
  },
];

export const TIME_ZONE_FROM_SHORT = {
  'PST': 'America/Los_Angeles',
  'PDT': 'America/Santa_Isabel',
  'MDT': 'America/Chihuahua',
  'NZDT': 'Pacific/Auckland',
  'AEST': 'Australia/Melbourne',
  'ACST': 'Australia/Darwin',
  'AWST': 'Australia/Perth',
  'EEST': 'Africa/Tripoli',
  'CEST': 'Africa/Ceuta',
  'WEST': 'Europe/Lisbon',
  'GMT/UTC': 'Europe/London',
  'ADT': 'America/Glace_Bay',
  'EDT': 'America/Detroit',
  'EST': 'America/Havana',
  'CDT': 'America/Chicago',
};

export const LOCAL_TIME_DISPLAYING = true;

export const PACKAGE_TYPES = {
  SINGLE: 'single',
  CATEGORY: 'category',
};

export const QUERY = {
  CHANNEL: 'channel',
  DIVISION: 'division',
  GROUP: 'group',
};

export const STRIPE_API_VERSION = '2022-08-01';
