export const PAGES_NAMES = {
  LOGIN: 'login',
  REGISTRATION: 'registration',
  VERIFY_NOTICE: 'verify-notice',
  VERIFY_EMAIL: 'verify',
  PASSWORD_RESET_REQUEST: 'password-reset-request',
  PASSWORD_RESET: 'password-reset',
  PROFILE: 'profile',
  PROFILE_EDIT: 'profile-edit',
  CONTACTS: 'contacts',
  BUY: 'buy',
  SELECT_PACKAGE: 'select-package',
  FAQ: 'faq',
  SIMULATE: 'simulate',
  ERROR: 'error-page',
  ABOUT: 'about',
  HOME: 'home',
  GAMES: 'games',
  PACKAGES: 'packages',
  CLIPS: 'clips',
  WATCH: 'watch',
  EMBEDDED_WATCH: 'embedded-watch',
  CLIPS_WATCH: 'clips-watch',
  VOD: 'vod',
  UPCOMING: 'upcoming',
  SCHEDULE: 'schedule',
  EVENTS: 'events',
};

export const LOGIN = {
  name: PAGES_NAMES.LOGIN,
  path: '/login',
};
export const REGISTRATION = {
  name: PAGES_NAMES.REGISTRATION,
  path: '/registration',
};
export const VERIFY_NOTICE = {
  name: PAGES_NAMES.VERIFY_NOTICE,
  path: '/verify-notice',
};
export const VERIFY_EMAIL = {
  name: PAGES_NAMES.VERIFY_EMAIL,
  path: '/verify-email',
};
export const PASSWORD_RESET_REQUEST = {
  name: PAGES_NAMES.PASSWORD_RESET_REQUEST,
  path: '/password-reset-request',
};
export const PASSWORD_RESET = {
  name: PAGES_NAMES.PASSWORD_RESET,
  path: '/password-reset',
};
export const PROFILE = {
  name: PAGES_NAMES.PROFILE,
  path: '/profile',
};
export const PROFILE_EDIT = {
  name: PAGES_NAMES.PROFILE_EDIT,
  path: '/profile/edit',
};
export const CONTACTS = {
  name: PAGES_NAMES.CONTACTS,
  path: '/contacts',
};
export const BUY = {
  name: PAGES_NAMES.BUY,
  path: '/buy',
};
export const SELECT_PACKAGE = {
  name: PAGES_NAMES.SELECT_PACKAGE,
  path: '/select-package/:id(\\d+)',
};
export const FAQ = {
  name: PAGES_NAMES.FAQ,
  path: '/faq',
};
export const SIMULATE = {
  name: PAGES_NAMES.SIMULATE,
  path: '/simulate',
};
export const ERROR = {
  name: PAGES_NAMES.ERROR,
  path: '/404',
};
export const ABOUT = {
  name: PAGES_NAMES.ABOUT,
  path: '/about',
};
export const HOME = {
  path: '/',
  name: PAGES_NAMES.HOME,
};
export const GAMES = {
  name: PAGES_NAMES.GAMES,
  path: '/games',
};
export const PACKAGES = {
  name: PAGES_NAMES.PACKAGES,
  path: '/packages',
};
export const CLIPS = {
  name: PAGES_NAMES.CLIPS,
  path: '/vod_library',
};
export const WATCH = {
  name: PAGES_NAMES.WATCH,
  path: '/watch/:id(\\d+)',
};
export const EMBEDDED_WATCH = {
  name: PAGES_NAMES.EMBEDDED_WATCH,
  path: '/watch_embed/:id(\\d+)',
};
export const CLIPS_WATCH = {
  name: PAGES_NAMES.CLIPS_WATCH,
  path: '/watch_library/:id(\\d+)',
};
export const VOD = {
  name: PAGES_NAMES.VOD,
  path: '/vod',
};
export const UPCOMING = {
  name: PAGES_NAMES.UPCOMING,
  path: '/upcoming',
};
export const SCHEDULE = {
  name: PAGES_NAMES.SCHEDULE,
  path: '/schedule',
};
export const EVENTS = {
  name: PAGES_NAMES.EVENTS,
  path: '/events',
};


export const PAGES_PATHS = {
  LOGIN,
  REGISTRATION,
  VERIFY_NOTICE,
  VERIFY_EMAIL,
  PASSWORD_RESET_REQUEST,
  PASSWORD_RESET,
  PROFILE,
  PROFILE_EDIT,
  CONTACTS,
  BUY,
  SELECT_PACKAGE,
  FAQ,
  SIMULATE,
  ERROR,
  ABOUT,
  HOME,
  GAMES,
  PACKAGES,
  CLIPS,
  WATCH,
  EMBEDDED_WATCH,
  CLIPS_WATCH,
  VOD,
  UPCOMING,
  SCHEDULE,
  EVENTS,
};
