import api from '../../api/api';
import {GET_PARTICIPANTS_LIST} from '../actionTypes';
import {UPDATE_PARTICIPANTS_LIST} from '../mutationTypes';

export default {
  [GET_PARTICIPANTS_LIST]: async ({commit}, params) => {
    const participants = await api.participantApi.get_participants(params);
    commit(UPDATE_PARTICIPANTS_LIST, participants);
    return participants;
  },
};
