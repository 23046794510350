import request from './request';

export default {
  get_events_list(params = {}) {
    return request('/events', params, 'get');
  },
  get_event(id) {
    return request('/events/' + id, {}, 'get');
  },
  download_event({id}) {
    return request(`download-event/${id}`, {}, 'get');
  },
  get_event_categories(params = {}) {
    return request('/events-categories', params, 'get');
  },
};
