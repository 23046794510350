import api from '../../api/api';
import * as ACTIONS from '../actionTypes';
import * as MUTATION from '../mutationTypes';

export default {
  [ACTIONS.GET_CHANNEL_SETTINGS]: async ({commit}) => {
    commit(MUTATION.UPDATE_CHANNEL_SETTINGS, await api.channelsApi.get_channel_settings());
  },
  [ACTIONS.GET_CHANNEL_MENU]: async ({commit}) => {
    commit(MUTATION.UPDATE_CHANNEL_MENU, await api.channelsApi.get_channel_menu());
  },
  [ACTIONS.GET_CHANNEL_SLIDES]: async () => {
    return await api.channelsApi.get_channel_slides();
  },
  [ACTIONS.GET_CHANNEL_PAGES]: async ({commit}) => {
    commit(MUTATION.UPDATE_CHANNEL_PAGES, await api.channelsApi.get_channel_pages());
  },
  [ACTIONS.GET_CHANNEL_PAGE]: async (state, params) => {
    return await api.channelsApi.get_channel_page(params);
  },
};
