import mutations from './mutations';
import actions from './actions';
import getters from '../../store/participant/getters';

export const initialState = {
  participantsList: [],
};

export default {
  state: {
    ...initialState,
  },
  mutations,
  actions,
  getters,
};
