import getters from './getters';
import mutations from './mutations';
import actions from './actions';

export const initialState = {};

export default {
  state: {
    user: null,
    channel_key: null,
    subdoman: null,
    api_key: null,
    ...initialState,
  },
  mutations,
  actions,
  getters,
};
