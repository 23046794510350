import request from './request';

export default {
    get_faq(params = {}) {
        return request('/faq', params, 'get')
            .then(res => {
                return res;
            });
    }
}
