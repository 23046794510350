import {ADD_TOAST, REMOVE_TOAST} from '../mutationTypes';

const DEFAULT_TOAST_DATA = {
  time: 5000,
  text: 'text',
  type: 'info',
};

export default {
  [ADD_TOAST](state, data = {}) {
    const newItem = {
      ...DEFAULT_TOAST_DATA,
      ...data,
      id: +(new Date()),
    };
    state.toastList = [...state.toastList, newItem];
  },
  [REMOVE_TOAST](state, data) {
    state.toastList = state.toastList.filter((toast) => toast !== data);
  },
};
