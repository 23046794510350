import api from '../../api/api';
import * as ACTIONS from '../actionTypes';
import {
  UPDATE_CHANNEL_KEY,
  UPDATE_API_KEY,
  UPDATE_USER_INFO,
  CLEAR_USER_INFO,
} from '../mutationTypes';

export default {
  [ACTIONS.GET_CHANNEL_KEY]: async ({commit}) => {
    commit(UPDATE_CHANNEL_KEY, await api.authApi.get_channel_key());
  },
  [ACTIONS.GET_API_KEY]: async ({commit}) => {
    commit(UPDATE_API_KEY, await api.authApi.get_api_key());
  },
  [ACTIONS.GET_PROFILE]: async ({commit}) => {
    commit(UPDATE_USER_INFO, await api.authApi.profile());
  },
  [ACTIONS.DO_LOGIN]: async ({commit}, params) => {
    commit(UPDATE_USER_INFO, await api.authApi.login(params));
  },
  [ACTIONS.DO_REGISTRATION]: async ({commit}, params) => {
    commit(UPDATE_USER_INFO, await api.authApi.registration(params));
  },
  [ACTIONS.DO_LOGOUT]: async ({commit}, params) => {
    commit(CLEAR_USER_INFO, await api.authApi.logout(params));
  },
  [ACTIONS.DO_VERIFY]: async (state, params) => {
    return await api.authApi.verify(params);
  },
  [ACTIONS.DO_PASSWORD_RESET_REQUEST]: async (state, params) => {
    return await api.authApi.passwordResetRequest(params);
  },
  [ACTIONS.DO_PASSWORD_RESET]: async (state, params) => {
    return await api.authApi.passwordReset(params);
  },
  [ACTIONS.DO_UPDATE_PROFILE]: async ({commit}, params) => {
    commit(UPDATE_USER_INFO, await api.authApi.updateProfile(params));
  },
};
