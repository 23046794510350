import request from './request';

export default {

    get_vod_medias: function( params = {}, token) {
        return request('vod-media-items', params, 'get', undefined, token)
    },
    get_vod_media(id, token) {
        return request('/vod-media-items/' + id, {}, 'get', undefined, token);
    },
    get_media_categories: function( params = {}) {
        return request('vod-media-categories', params, 'get')
    },
    vod_search: function ({
                              limit = 10,
                              offset = 0,
                              title,
                              sort_field = 'date',
                              ...rest
                          } = {}, token) {
        const params = { limit, offset, title, sort_field, ...rest }
        return request('vod-media/search', params, 'get', undefined, token)
    }
}
