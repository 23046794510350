import api from '../../api/api';
import {GET_EVENTS_LIST, GET_EVENT, DOWNLOAD_EVENT, GET_EVENT_CATEGORIES} from '../actionTypes';

export default {
  [GET_EVENTS_LIST]: async ({state, commit}, params = {}) => {
    const {
      requestParams = params,
      callbacks: {
        onSuccess,
        onFinally,
      } = {},
    } = params;

    const res = await api.eventsApi.get_events_list(requestParams);
    onSuccess?.(res);
    onFinally?.();
    return res;
  },
  [GET_EVENT]: async (state, params) => {
    return await api.eventsApi.get_event(params);
  },
  [DOWNLOAD_EVENT]: async (_, params) => {
    return await api.eventsApi.download_event(params);
  },
  [GET_EVENT_CATEGORIES]: async ({state, commit}, params) => {
    api.eventsApi.get_event_categories(params)
        .then(({data = []}) => {
          commit('UPDATE_EVENTS_CATEGORIES', data);
        })
        .catch(() => {
        });
  },
};
