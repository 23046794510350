import {loadScript} from '@/utils/helpers';

/**
 * Run Google Analytics
 */
class GTag {
  #gtagUrl = 'https://www.googletagmanager.com/gtag/js';
  #scriptLoaded = false;
  #trackID = null;

  /**
   * Install G-TAG id from admin for tracking
   * @param {string} id
   */
  set trackingId(id) {
    if (!id) return false;
    this.#trackID = id;
    this.#initScript();
  }

  /**
   * add events to Google data array to init track request
   * @param {any|any[]} args
   */
  #track(...args) {
    if (!this.#scriptLoaded) return;
    window.dataLayer.push(args);
  }

  /**
   * Script loaded callback according to Google documentation
   */
  #scriptOnload() {
    this.#scriptLoaded = true;
    window.dataLayer = window.dataLayer || [];
    this.#track('js', new Date());
    this.#track('config', 'GA_TRACKING_ID');
  }

  /**
   * Load Google Analytics script
   */
  #initScript() {
    const url = new URL(this.#gtagUrl);
    url.searchParams.set('id', this.#trackID);
    loadScript(url.href, () => this.#scriptOnload());
  }

  // TRACKING INTERFACE
  /**
   * track login event to GA
   * @param {string} method [Google|Facebook|site]
   */
  login(method) {
    this.#track('event', 'login', {'method': method}); // method = Google, Facebook, site
  }

  /**
   * Track page load event
   * @param {string} path [url]
   */
  pageView(path) {
    this.#track('set', 'page_path', path);
    this.#track('event', 'page_view');
  }
}

export default new GTag();
