import mutations from './mutations';
import actions from './actions';

export const initialState = {
  packagesList: [],
  ordersList: [],
  subscriptionsList: [],
};

export default {
  state: {
    ...initialState,
  },
  mutations,
  actions,
};
