import Vue from 'vue';
import VueRouter from 'vue-router';
import NotFound from './components/NotFound';
import store from './store/store';
import GoogleAnalytics from '@/components/base-components/Analytics/GoogleAnalytics';
import {PAGES_NAMES, PAGES_PATHS} from '@/pages';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    return savedPosition ? savedPosition : {x: 0, y: 0};
  },
  routes: [
    {
      ...PAGES_PATHS.LOGIN,
      meta: {
        title: 'Login',
      },
      component: () => import(
          /* webpackChunkName: "auth-view" */ './views/LoginScreen.vue'
      ),
    },
    {
      ...PAGES_PATHS.REGISTRATION,
      meta: {
        title: 'Registration',
      },
      component: () => import(
          /* webpackChunkName: "auth-view" */ './views/RegistrationScreen.vue'
      ),
    },
    {
      ...PAGES_PATHS.VERIFY_NOTICE,
      meta: {
        title: 'Verify notice',
      },
      component: () => import(
          /* webpackChunkName: "auth-view" */ './views/VerifyNoticeScreen.vue'
      ),
    },
    {
      ...PAGES_PATHS.VERIFY_EMAIL,
      meta: {
        title: 'Verify',
      },
      component: () => import(
          /* webpackChunkName: "auth-view" */ './views/VerifyScreen.vue'
      ),
    },
    {
      ...PAGES_PATHS.PASSWORD_RESET_REQUEST,
      meta: {
        title: 'Password reset request',
      },
      component: () => import(
          // eslint-disable-next-line max-len
          /* webpackChunkName: "auth-view" */ './views/PasswordResetRequestScreen.vue'
      ),
    },
    {
      ...PAGES_PATHS.PASSWORD_RESET,
      meta: {
        title: 'Password reset',
      },
      component: () => import(
          /* webpackChunkName: "auth-view" */ './views/PasswordResetScreen.vue'
      ),
    },
    {
      ...PAGES_PATHS.PROFILE,
      meta: {
        title: 'Profile',
      },
      component: () => import(
          /* webpackChunkName: "schedule-view" */ './views/ProfileScreen.vue'
      ),
      children: [
        {
          ...PAGES_PATHS.PROFILE_EDIT,
          meta: {
            title: 'Update profile',
          },
          component: () => import(
              // eslint-disable-next-line max-len
              /* webpackChunkName: "schedule-view" */ './views/ProfileScreen.vue'
          ),
        },
      ],
    },
    {
      ...PAGES_PATHS.CONTACTS,
      meta: {
        title: 'Contacts',
      },
      component: () => import(
          /* webpackChunkName: "contacts-view" */ './views/ContactScreen.vue'
      ),
    },
    {
      ...PAGES_PATHS.BUY,
      meta: {
        title: 'Buy',
      },
      component: () => import(
          /* webpackChunkName: "buy-view" */ './views/BuyScreen.vue'
      ),
    },
    {
      ...PAGES_PATHS.SELECT_PACKAGE,
      meta: {
        title: 'Select package',
      },
      component: () => import(
          /* webpackChunkName: "buy-view" */ './views/SelectPackageScreen.vue'
      ),
    },
    {
      ...PAGES_PATHS.FAQ,
      meta: {
        title: 'FAQ',
      },
      component: () => import(
          /* webpackChunkName: "faq-view" */ './views/FaqScreen.vue'
      ),
    },
    {
      ...PAGES_PATHS.SIMULATE,
      meta: {
        title: 'Simulate',
      },
      component: () => import(
          /* webpackChunkName: "simulate-view" */ './views/SimulateScreen.vue'
      ),
    },
    {
      ...PAGES_PATHS.ERROR,
      meta: {
        title: '404',
      },
      component: NotFound,
    },
    {
      ...PAGES_PATHS.ABOUT,
      meta: {
        title: 'About us',
      },
      component: () => import(
          /* webpackChunkName: "faq-view" */ './views/AboutUsScreen.vue'
      ),
    },
  ],
});

const NOT_REMEMBERED_PAGES = [
  PAGES_NAMES.LOGIN,
  PAGES_NAMES.REGISTRATION,
  PAGES_NAMES.VERIFY_NOTICE,
  PAGES_NAMES.PASSWORD_RESET_REQUEST,
  PAGES_NAMES.PASSWORD_RESET,
  PAGES_NAMES.ERROR,
  PAGES_NAMES.VERIFY_EMAIL,
];

const DISABLE_FOR_LOGGED = [
  PAGES_PATHS.LOGIN.path,
  PAGES_PATHS.REGISTRATION.path,
  PAGES_PATHS.PASSWORD_RESET_REQUEST.path,
  PAGES_PATHS.PASSWORD_RESET.path,
];
const PATH_TO_REDIRECT_IF_LOGGED = '/';

/**
 * @param store: Object
 * @param store.state: Object
 * @param store.state.auth.user.temporary_password: Boolean
 */
router.beforeEach((to, from, next) => {
  const logged = store.getters.isLogged;
  const hasTempPass = store.state?.auth?.user?.temporary_password;
  if (logged && hasTempPass && (PAGES_PATHS.PROFILE_EDIT.path !== to.path)) {
    if (PAGES_PATHS.PROFILE_EDIT.path === from.path) {
      return false;
    }
    next(PAGES_PATHS.PROFILE_EDIT);
  } else if (logged && DISABLE_FOR_LOGGED.includes(to.path)) {
    next(PATH_TO_REDIRECT_IF_LOGGED);
  } else if (NOT_REMEMBERED_PAGES.includes(to.name)) {
    next();
  } else {
    Vue.$storage.set('last_page', to.path);
    Vue.$storage.set('last_page_query', to.query);
    Vue.$storage.set('last_page_params', to.params);
    next();
  }
});
router.afterEach((to) => {
  GoogleAnalytics.pageView(to.fullPath);
});
export default router;
