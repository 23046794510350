import {VALIDATION_REG_EXP} from '@/const';

/**
 * Get correct type of constructor
 * @param {constructor} fn
 * @return {string|string}
 */
function getType(fn) {
  const match = fn && fn.toString().match(/^\s*function (\w+)/);
  return match ? match[1] : '';
}

/**
 * Check is both types are same
 * @param {constructor} type - function constructor.
 * @param {constructor} expectedType - name of expected constructor: Array, Number, Object etc.
 * @return {boolean}
 */
export function isSameType(type, expectedType) {
  return getType(type) === getType(expectedType);
}

/**
 * Function to load script from url.
 * @param {string} url -  Download url.
 * @param {function|undefined} cb - onLoad callback.
 */
export function loadScript(url, cb) {
  const tag = document.createElement('script');
  tag.src = url;
  tag.onload = () => cb?.();
  const firstScriptTag = document.getElementsByTagName('script')[0];
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
}

/**
 * Separate abortSignal from request params
 * @param {object} params
 * @return {{token: ({abortSignal}|undefined), props:  object}}
 */
export function ejectAbortSignal(params) {
  let token = undefined;
  if (params.abortSignal) {
    token = params.abortSignal;
    delete params.abortSignal;
  }
  return {
    token,
    props: params,
  };
}

/**
 * Get from Event Object home team and away team data object
 * @param {object} event
 * @param {Array.<Object>} event.participants
 * @param {String|Number} event.home_team_id
 * @return {{awayTeam: {}, homeTeam: {}}}
 */
export function getAwayHomeTeams({participants, home_team_id: homeTeamId} = {}) {
  const homeId = homeTeamId;
  const homeTeam = {};
  const awayTeam = {};

  participants?.forEach((team) => {
    let teamObj;
    if (homeId) {
      teamObj = Number(team.id) === Number(homeId) ? homeTeam : awayTeam;
    } else {
      teamObj = Object.keys(awayTeam).length ? homeTeam : awayTeam;
    }
    teamObj.name = team.name;
    teamObj.img = team.images;
  });

  return {
    homeTeam,
    awayTeam,
  };
}

/**
 * Get regular string and
 * return string consists from substring divided by separator and wrapped to html tag
 * @param {string} str
 * @param {string} separator
 * @param {string} tag
 * @return {string}
 */
export function parseStringBySeparatorToHtml(str, separator = '\r\n', tag = 'p') {
  if (!str) return '';
  const strArr = str.split(separator);
  return strArr.reduce((total, next) => {
    return `${total}<${tag}>${next}</${tag}>`;
  }, '');
}

/**
 * @param {string} email
 * @return {boolean}
 */
export function validateEmail(email) {
  return VALIDATION_REG_EXP.email.test(email) || VALIDATION_REG_EXP.numberEmail.test(email);
}
