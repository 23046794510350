import Vue from 'vue';
import router from './routers';
import store from './store/store';
import {UPDATE_USER_INFO} from './store/mutationTypes';

import './utils/filters';
import './utils/plugins';
import './utils/injections';
import './utils/social';
import './utils/directives';
import './assets/css/main.scss';
import {CHANNEL_TYPE} from './const';
import LoaderComponent from './components/LoaderComponent';
import * as ACTION from '@/store/actionTypes';

const APP_BUILDERS = {
  [CHANNEL_TYPE.league]: {
    routes: './league/leagueRouters',
    renderedApp: './league/LeagueApp',
  },
  [CHANNEL_TYPE.conference]: {
    routes: './conference/conferenceRouters',
    renderedApp: './conference/ConferenceApp',
  },
  [CHANNEL_TYPE.entertainment]: {
    routes: '',
    renderedApp: './entertainment/EntertainmentApp',
  },
};

window.vm = new Vue({
  el: '#app',
  components: {LoaderComponent},
  template: `
    <div id='app' 
         class='d-flex align-items-center justify-content-center' 
         :style="{height: '100vh'}"
    >
      <LoaderComponent/>
    </div>`,
});

store.dispatch(ACTION.GET_CHANNEL_KEY)
    .then(() => store.dispatch(
        ACTION.GET_API_KEY,
        {channel_key: Vue.$storage.get('channel_key')},
    ))
    .then(() => Promise.allSettled([
      store.dispatch(ACTION.GET_CHANNEL_MENU),
      store.dispatch(ACTION.GET_CHANNEL_PAGES),
      store.dispatch(ACTION.GET_CHANNEL_SETTINGS),
      store.dispatch(ACTION.GET_PROFILE),
    ]))
    .catch((err) => {
      store.commit(UPDATE_USER_INFO, null);
      return err;
    })
    .finally(async () => {
      let renderApp;
      let appRoutes = [];
      const dataForBuild = APP_BUILDERS[Vue.$storage.get('content_type')];

      await Promise.allSettled([
        import(`${dataForBuild.renderedApp}`)
            .then((m) => renderApp = m.default)
            .catch(() => console.warn('app file not found')),
        import(`${dataForBuild.routes}`)
            .then((m) => appRoutes = m.default)
            .catch(() => console.warn('routers file not found')),
      ]);

      const newRoutersArr = [
        ...(appRoutes),
        ...(store.getters.getChannelRoute || []),
        {
          path: '*',
          redirect: '/404',
        },
      ];
      newRoutersArr.forEach( (route) => router.addRoute(route));

      window.vm = new Vue({
        router,
        store,
        render: (h) => h(renderApp),
      }).$mount('#app');
    });

