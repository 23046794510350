import Vue from 'vue';
import Vuex from 'vuex'; // https://vuex.vuejs.org/ru/guide/
import createCache from 'vuex-cache';

Vue.use(Vuex);


import auth, {initialState as authInitState} from './auth/auth';
import channel, {initialState as channelInitState} from './channel/channel';
import event, {initialState as eventInitState} from './event/event';
import participant, {initialState as participantInitState} from './participant/participant';
import faq, {initialState as faqInitialState} from './faq/faq';
import payment, {initialState as paymentInitialState} from './payment/payment';
import calendar, {initialState as calendarInitialState} from './calendar/calendar';
import customForm, {initialState as customFormInitialState} from './customForm/customForm';
import vodMedia, {initialState as vodMediaInitialState} from './vod-media/vodMedia';
import geo, {initialState as geoInitialState} from './geo/geo';
import settings, {initialState as settingsInitialState} from './settings/settings';
import {NODE_ENV} from '@/const';
import {CLEAR_CACHE, RESET_STATE} from '@/store/mutationTypes';

const initialState = {
  auth: authInitState,
  channel: channelInitState,
  event: eventInitState,
  participant: participantInitState,
  faq: faqInitialState,
  payment: paymentInitialState,
  calendar: calendarInitialState,
  customForm: customFormInitialState,
  vodMedia: vodMediaInitialState,
  geo: geoInitialState,
  settings: settingsInitialState,
};
export default new Vuex.Store({
  strict: NODE_ENV !== 'production',
  plugins: [createCache({timeout: 1000 * 60})],
  modules: {
    auth,
    channel,
    event,
    participant,
    faq,
    payment,
    calendar,
    customForm,
    vodMedia,
    geo,
    settings,
  },
  state: {},
  mutations: {
    [RESET_STATE](state) {
      Object.keys(state).forEach((key) => {
        typeof state[key] === 'object' && state[key] !== null ?
          Object.assign(state[key], initialState[key]) :
          initialState[key] !== 'undefined' ? state[key] = initialState[key] : null;
      });
    },
    [CLEAR_CACHE]() {
      this.cache.clear();
    },
  },
});
