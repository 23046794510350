/**
 * Insert color variables from admin to html
 * @param {object} colorObj
 */
export function createColorVariables(colorObj) {
  let stylesString = ``;
  Object.entries(colorObj).forEach(([variable, value]) => {
    value && (stylesString += `--settings-${variable}:${value};`);
  });
  stylesString = `body{${stylesString}}`;

  const style = document.createElement('style');
  style.setAttribute('type', 'text/css');
  style.innerHTML = stylesString;

  document.head.appendChild(style);
}
