import Vue from 'vue';
import axios from 'axios';
import _ from 'lodash';
import {API, ERROR} from '../../const';

const BASE_URL = API.API_HOST;

export default function (url, params = {}, method = 'post', baseUrl = BASE_URL, token) {

    axios.interceptors.response.use(function (response) {
        if (response.status !== 200) {
            return Promise.reject(response.data);
        }
        return response;
    });
    if (Vue.$storage.get('api_key')) {
        Object.assign(params,{
            api_key: Vue.$storage.get('api_key')
        });
    }

    if(method === 'get'){
        let encodedUriStr = Object.entries(params).map(([prop,val])=> {
            if (Array.isArray(params[prop])) {
                return params[prop].map((value) => `${prop}[]=${value}`).join('&')
            }
            return `${encodeURIComponent(prop)}=${encodeURIComponent(val)}`;
        }).join('&');
        url = `${url}?${encodedUriStr}`
    }

    return axios({
        url: url,
        method: method,
        baseURL: baseUrl,
        timeout: 30000,

        transformRequest: [function (data) {
            let fd = new FormData();

            _.forIn(data, (value, key) => {
                fd.append(key, value)
            });
            return fd;
        }],
        data: params,
        signal: token,
    })
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            if (err.code === ERROR.CANCELED) {
                return Promise.reject(err)
            }
            if(err.response.data && err.response.data.error && err.response.data.error.errors  && err.response.data.error.errors.channel_key){
                Vue.$storage.pull('api_key');
                Vue.$storage.pull('channel_key');
            }
            if (err.response.data && err.response.data.error && err.response.data.error.errors  && err.response.data.error.errors.api_key) {
                Vue.$storage.pull('api_key');
                window.location.reload(true);
            }


            return Promise.reject(err.response.data);
        });
}
